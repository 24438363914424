@import "./variables";

.voz-form {
  .form-group {
    display: flex;
    flex-direction: column;
  }

  .multi-form-group {
    display: flex;
    flex-direction: column;
    @media (min-width: 991.98px) {
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  .multi-form-group > .form-group + .form-group {
    margin: 16px 0 0 0;
    @media (min-width: 991.98px) {
      margin: 0 0 0 16px;
    }
  }

  .form-group + .form-group,
  .form-group + .multi-form-group,
  .multi-form-group + .form-group,
  .date-time-container + .form-group {
    margin-top: 16px;
  }

  .form-group .form-check {
    display: flex;
    align-items: center;

    input {
      width: 22px;
      height: 22px;
      padding: 11px;
      border-radius: 11px;
      background-color: #26ac75;
    }

    input:not(:disabled) {
      cursor: pointer;
      pointer-events: all;
    }

    input:focus {
      border: none;
      box-shadow: none;
    }

    label {
      margin: 3px 0 0 6px;
    }
  }

  .form-group .form-check + .form-check {
    margin: 2px 0 0 0;
  }

  input:not([type="file"]),
  textarea {
    padding: 10px 12px;
    border: 0px;
    border-radius: 8px;
    background-color: #eeeeee;
    transition: box-shadow 0.1s ease-in-out;
  }

  textarea:focus,
  input:focus {
    box-shadow: 0 0 0 1px lighten(gray, 25%);
  }

  select {
    padding: 13px 0px 13px 13px;
    border: 1px solid lightgray;
  }

  input:disabled,
  button[disabled] {
    cursor: not-allowed;
    pointer-events: all;
  }

  .input-error {
    box-shadow: 0 0 0 1px #ff5861;
  }

  .label-container {
    display: flex;
    justify-content: space-between;

    padding: 0 4px;

    label {
      font-weight: 400;
      font-size: 17px;
      min-width: fit-content;
    }

    span.character-count {
      font-weight: 500;
    }
  }

  .label-suggestion {
    color: #a2a9b3;
  }

  .date-time-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .form-group + .form-group {
      margin: 0 0 0 10px;
    }
  }

  .role-checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 0.3rem;
    padding-left: 4px;

    label {
      margin: 0;
    }

    input[type="checkbox"] {
      filter: hue-rotate(301deg);
      opacity: 0.85;
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }
  }

  .checkbox-group {
    .form-group + .form-group {
      margin-top: 0;
    }
  }

  .role-label-container {
    display: flex;
    align-items: center;

    margin-bottom: 0.5rem;

    label {
      margin: 0;
    }
  }

  .role-label-container .role-actions {
    display: flex;
    min-width: fit-content;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 30px;
    }

    button + button {
      margin-left: 10px;
    }
  }

  // override Angular Bootstrap time picker input outline
  input.ngb-tp-input:focus {
    box-shadow: 0 0 0 1px lighten(gray, 25%);
  }

  input[type="file"].form-control:focus {
    border-color: #ced4da;
  }

  button.btn-select-date {
    min-height: 44px;
    margin-left: 3px;
    border-radius: 0 0.25rem 0.25rem 0;
  }

  button.btn-select-date:focus {
    box-shadow: none !important;
  }

  .btn-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 54px;
    border-radius: 8px;
  }

  .dropdown-menu {
    padding: 10px 2px;
    border-radius: 8px;
    min-width: max-content;
  }

  .dropdown-item {
    font-size: 16px;
    &:hover {
      background-color: whitesmoke;
    }
    &:active {
      color: #212529;
      background-color: #e1e1e1;
    }
  }

  .image-preview {
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      width: 100%;
    }

    a img {
      width: 100%;
      border-radius: 4px 4px 0 0;
      object-fit: cover;
    }

    a img.round-border {
      border-radius: 4px;
    }

    .remove-img-container {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 4px 0;
      margin-top: 1px;

      border-radius: 0 0 4px 4px;
      color: white;
      background-color: #464646;
    }

    .remove-img-container:hover {
      background-color: lighten(#464646, 3%);
      cursor: pointer;
    }
  }

  input[type="file"] + .image-preview {
    margin-top: 6px;
  }

  .image-preview + input[type="file"] {
    margin-top: 6px;
  }

  .error-wrapper {
    padding: 2px 6px;
    margin-bottom: 6px;
    color: white;
    border-radius: 4px;
    background-color: darken($voz-text-error, 10%);
  }
}

// Button Container
form.voz-form .btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;

  button + button {
    margin-left: 10px;
  }

  button[type="submit"] {
    min-width: 148px;
    min-height: 48px;
  }
}

// Bookable form
.voz-form.bookable-form {
  .availability-group {
    padding-left: 6px;
  }

  .availability-group .label-container {
    padding: 0;
    margin-bottom: 8px;
  }

  input:not([type="file"]) {
    padding: 8px 10px;
  }
}

// Weekday container
.settings-form .weekday-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 30px;

  &.active {
    background-color: green;
  }

  .input-group {
    width: fit-content;
    display: flex;
    align-items: center;
    height: 40px;

    input[type="text"] {
      font-size: 13px;
    }
  }

  span {
    margin: 0 6px !important;
  }

  ::ng-deep input[type="text"]::placeholder {
    opacity: 0;
  }

  input[type="checkbox"] {
    height: 18px;
    width: 18px;
  }

  label {
    display: flex;
    align-items: center;
    min-width: 100px;
    font-size: 14px;
    margin: 0;
    padding: 0 0 0 6px;
  }
}
