/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@font-face {
  font-family: "Lusanne";
  font-weight: 200;
  src: url("./assets/fonts/TWKLusanne/TWKLausanne-200.ttf");
  src: url("./assets/fonts/TWKLusanne/TWKLausanne-200.woff") format("woff");
}
@font-face {
  font-family: "Lusanne";
  font-weight: 400;
  src: url("./assets/fonts/TWKLusanne/TWKLausanne-400.ttf");
  src: url("./assets/fonts/TWKLusanne/TWKLausanne-400.woff") format("woff");
}
@font-face {
  font-family: "Lusanne";
  font-weight: 600;
  src: url("./assets/fonts/TWKLusanne/TWKLausanne-600.ttf");
  src: url("./assets/fonts/TWKLusanne/TWKLausanne-600.woff") format("woff");
}

@import "./scss/variables";
@import "./scss/responsive-table";
@import "./scss/voz-custom-panel";
@import "./scss/voz-modal";
@import "./scss/voz-form";
@import "./scss/voz-form-apartments";
@import "./scss/voz-form-select-list";
@import "./scss/buttons";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  word-break: break-word;
}

* {
  font-family: "Lusanne", sans-serif !important;
  font-weight: 200;
}

html {
  font-family: "Lusanne", sans-serif !important;
  scrollbar-gutter: stable;
  font-size: 14px;
}

body {
  font-family: "Lusanne", sans-serif !important;
  background-color: #f8f9fa;

  &.login-body {
    background: white;
  }

  &.modal-open {
    padding-right: 0 !important;
  }
}

*:focus {
  outline: none !important;
}
.modal-title {
  font-size: 18px;
  font-weight: 600;
}

.grouped-rounded-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 8px;
  font-size: small;
  border: 0.5px solid #ccc;
  margin-bottom: 10px;
  margin-top: 10px;

  .form-group {
    display: flex;
    margin-top: 0px !important;

    label {
      font-size: 15px;
    }
  }
}
.bordered-group-header-row {
  margin-top: 25px;
  padding-bottom: 15px;
  .reset-btn {
    margin-top: -7px;
    font-size: 12px;
    font-weight: 600;
    color: #333;
    border: 0.5px solid #ccc;
    border-radius: 8px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 3px;
    display: flex;
    // text-transform: uppercase;
    align-items: center;
    margin-bottom: 10px;
  }
}

.bordered-group-container {
  margin-bottom: 8px;

  .bordered-group-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 10px;
    row-gap: 15px;
    column-gap: 10px;
    .form-group {
      padding-top: 0px;
      margin-top: 0px !important;
    }
  }
}
.tooltip-container {
  position: absolute;
  z-index: 10000;
  background-color: #fff;
  box-shadow: #aaa 2px 2px 10px;
  border: 0.5px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  min-width: 200px;
  max-width: 550px;
  justify-self: anchor-center;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  ul {
    display: grid;
    row-gap: 8px;
  }
  li {
    list-style-type: "- ";
    text-align: left;
  }
  .sub-title {
    font-size: 13px;
    font-weight: 600;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 25px;
  }
}

.clickable,
*[ng-reflect-router-link] {
  cursor: pointer;
  pointer-events: all;
}

.hidden {
  display: none;
}

.line-clamp {
  display: -webkit-box;
  width: 95%;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.text-center {
  text-align: center;
}

.text-red {
  color: #ff3b30;
}

.deleted-user {
  color: #999999;
}

.label-muted {
  color: #8e8e93;
}

.fw-600 {
  font-weight: 600;
}

.text-sm {
  font-size: 0.875rem;
}

.form-group-bordered {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

// /* Importing Bootstrap SCSS file. */
// @import "bootstrap/scss/bootstrap";
