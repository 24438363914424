@import "./../scss/variables";

.voz-form .form-group.select-list-group {
  @include animation-fade-in(0.2s);
  margin-top: 8px;

  .search-filter-input {
    height: 28px;
    margin-bottom: 8px;
    border-radius: 4px;
  }

  .list-container {
    max-height: 250px;
    overflow-y: auto;

    .form-group + .form-group {
      margin-top: 0;
    }
  }
}

.voz-form .select-list-group .checkbox-label-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;

  label {
    margin: 0;
  }
}

.voz-form .select-list-group .checkbox-label-container .checkbox-actions {
  display: flex;
  min-width: fit-content;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 30px;
  }

  button + button {
    margin-left: 10px;
  }
}

.voz-form .select-list-group .checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 0.3rem;

  label {
    display: flex;
    width: 100%;
    margin: 0;
  }

  label span.full-name {
    min-width: max-content;
  }

  label span + span {
    margin-left: 4px;
  }

  input[type="checkbox"] {
    filter: hue-rotate(301deg);
    opacity: 0.85;
    height: 18px;
    width: 18px;
    margin-right: 10px;
  }
}

.voz-form .select-list-group .checkbox-container:last-child {
  margin-bottom: 0;
}
