@import "./variables";

.btn-light:not([disabled]):hover {
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:not([disabled]):active {
  background-color: #dae0e5;
  border-color: #d3d9df;
}

// Serneke Button
.btn.btn-voz {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 54px;
  width: 100%;
  margin: 0;
  padding: 12px 24px;

  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;

  @include button-variant(
    // background
    $voz-primary-3,
    // border-color
    $voz-primary-3,
    // hover:background-color
    white,
    // hover:background-color
    lighten($voz-primary-3, 2%),
    // active:background-color
    lighten($voz-primary-3, 2%),
    // active:background-color
    lighten($voz-primary-3, 2%)
  );

  transition: all 0.2s ease, visibility 0s;

  &:hover {
    color: white;
    opacity: 1;
    border-color: lighten($voz-primary-3, 2%);
    background-color: lighten($voz-primary-3, 2%);
  }

  &:disabled,
  &:active,
  &:focus,
  &:active:focus {
    color: white;
    opacity: 1;
    border-color: lighten($voz-primary-3, 2%);
    background-color: lighten($voz-primary-3, 2%);
  }
}

// Loading Button
.btn.btn-loading {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: loading-spinner 1s ease infinite;
    @keyframes loading-spinner {
      from {
        transform: rotate(0turn);
      }
      to {
        transform: rotate(1turn);
      }
    }
  }
}

// Table action Button (create new Story, etc)
.btn.btn-table-action,
.btn.btn-custom-voz-black {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  color: white;

  @include button-variant(
    // background
    lighten($voz-primary-3, 8%),
    // border-color
    lighten($voz-primary-3, 8%),
    // hover:background-color
    white,
    // hover:background-color
    lighten($voz-primary-3, 12%),
    // active:background-color
    lighten($voz-primary-3, 12%),
    // active:background-color
    lighten($voz-primary-3, 12%)
  );

  transition: all 0.2s ease, visibility 0s;

  fa-icon {
    margin-right: 6px;
  }

  &:hover {
    color: white;
    opacity: 1;
    border-color: lighten($voz-primary-3, 12%);
    background-color: lighten($voz-primary-3, 12%);
  }

  &:disabled,
  &:active,
  &:focus,
  &:active:focus {
    color: white;
    opacity: 1;
    border-color: lighten($voz-primary-3, 8%);
    background-color: lighten($voz-primary-3, 8%);
  }
}

.btn.btn-custom-voz-gold {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  color: white;

  @include button-variant(
    // background
    $voz-gold,
    // border-color
    $voz-gold,
    // hover:background-color
    white,
    // hover:background-color
    lighten($voz-gold, 6%),
    // active:background-color
    lighten($voz-gold, 6%),
    // active:background-color
    lighten($voz-gold, 6%)
  );

  transition: all 0.2s ease, visibility 0s;

  fa-icon {
    margin-right: 6px;
  }

  &:hover {
    color: white;
    opacity: 1;
    border-color: lighten($voz-gold, 6%);
    background-color: lighten($voz-gold, 6%);
  }

  &:disabled,
  &:active,
  &:focus,
  &:active:focus {
    color: white;
    opacity: 1;
    border-color: lighten($voz-gold, 4%);
    background-color: lighten($voz-gold, 4%);
  }
}

.btn.btn-custom-voz-warning {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  color: white;

  @include button-variant(
    // background
    $voz-secondary-16,
    // border-color
    $voz-secondary-16,
    // hover:background-color
    white,
    // hover:background-color
    darken($voz-secondary-16, 4%),
    // active:background-color
    darken($voz-secondary-16, 4%),
    // active:background-color
    darken($voz-secondary-16, 4%)
  );

  transition: all 0.2s ease, visibility 0s;

  fa-icon {
    margin-right: 6px;
  }

  &:hover {
    color: white;
    opacity: 1;
    border-color: darken($voz-secondary-16, 4%);
    background-color: darken($voz-secondary-16, 4%);
  }

  &:disabled,
  &:active,
  &:focus,
  &:active:focus {
    color: white;
    opacity: 1;
    border-color: darken($voz-secondary-16, 4%);
    background-color: darken($voz-secondary-16, 4%);
  }
}

// Button container
.btn.btn-custom-voz-success {
  width: 100%;
  min-height: 46px;
  margin-top: 12px;
  padding: 10px 22px;
  border-radius: 8px;
}

// Oval orange
button.btn-oval-orange {
  color: #ff3b30;
  border-color: #ff3b30;
  background-color: white;
  border-radius: 6px;

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.1rem rgba(245, 245, 245, 0.3);
  }

  /* all hover cases */
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: white;
    background-color: lighten(#ff3b30, 8%);
    border-color: #ff3b30;
  }
}

// Oval blue
button.btn-oval-blue {
  color: #64b7d9;
  border-color: #64b7d9;
  background-color: white;
  border-radius: 6px;

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.1rem rgba(245, 245, 245, 0.3);
  }

  /* all hover cases */
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: white;
    background-color: lighten(#64b7d9, 8%);
    border-color: #64b7d9;
  }
}

// Dotted border
.btn-dotted {
  padding: 4px 8px;
  margin: 8px 0;
  border: 1px dashed lightgray;
  border-radius: 8px;
  text-align: center;
  background-color: whitesmoke;
  font-size: 18px;
  font-weight: 300;
  transition: background-color 0.15s;
}

.btn-dotted:hover {
  background-color: darken(#f3f3f3, 4%);
}

.btn-dotted:disabled {
  cursor: not-allowed;
  pointer-events: all;
}
