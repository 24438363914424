@import "./../scss/variables";

.voz-form .form-group.select-apartments-group {
  @include animation-fade-in(0.2s);
  margin-top: 8px;

  .apartment-filter-input {
    height: 28px;
    margin-bottom: 8px;
    border-radius: 4px;
  }

  .apartments-container {
    max-height: 250px;
    overflow-y: auto;

    .form-group + .form-group {
      margin-top: 0;
    }
  }
}

.voz-form .select-apartments-group .checkbox-label-container {
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;

  label {
    margin: 0;
  }
}

.voz-form .select-apartments-group .checkbox-label-container .checkbox-actions {
  display: flex;
  min-width: fit-content;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 30px;
  }

  button + button {
    margin-left: 10px;
  }
}

.voz-form .select-apartments-group .checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 0.3rem;

  label {
    margin: 0;
  }

  input[type="checkbox"] {
    filter: hue-rotate(301deg);
    opacity: 0.85;
    height: 18px;
    width: 18px;
    margin-right: 10px;
  }
}

.voz-form .select-apartments-group .checkbox-container:last-child {
  margin-bottom: 0;
}

.dropdown {
  padding: 10px;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 10px top 50%, 0 0;
  border: 0.1px solid #ccc;
  background-color: #eee;
  padding: 13px 0px 13px 13px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
