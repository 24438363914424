@import "./variables";

.voz-modal {
  overflow-y: overlay;
}

// Modal Dialog
.voz-modal .modal-dialog {
  max-width: 600px;
  .modal-content {
    border: none;
  }
}

// TenantUnit contact dialog
.voz-modal.tu-contact-modal .modal-dialog {
  max-width: 400px;
}

// TenantUnit contact dialog
.voz-modal.tu-fault-report-modal .modal-dialog {
  max-width: 500px;
}

// TenantUnit fault report link dialog
.voz-modal.tu-fault-report-link-modal .modal-dialog {
  max-width: 500px;
}

// Building entrances
.voz-modal.building-entrances-modal .modal-dialog {
  max-width: 500px;
}

// Building levels
.voz-modal.building-levels-modal .modal-dialog {
  max-width: 500px;
}

// Level modal
.voz-modal.level-modal .modal-dialog {
  max-width: 500px;
}

// Entrance modal
.voz-modal.entrance-modal .modal-dialog {
  max-width: 500px;
}

// Post dialog
.voz-modal.post-modal .modal-dialog {
  max-width: 600px;
}

// Approve connection modal
.voz-modal.ds-connection-approve-modal .modal-dialog {
  max-width: 500px;
}

// Sever connection modal
.voz-modal.ds-connection-sever-modal .modal-dialog {
  max-width: 500px;
}

// Reload Digital Signage modal
.voz-modal.ds-reload-modal .modal-dialog {
  max-width: 500px;
}

// Send invites contact dialog
.voz-modal.send-apartment-invite-emails-modal .modal-dialog {
  max-width: 500px;
}

// Remove modal animation on backdrop click
.modal.modal-static .modal-dialog {
  -webkit-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}

// Modal Header
.voz-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 1rem 1rem 0 1rem;

  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #212529;
  }

  h5 {
    font-size: 16px;
    color: #8e8e93;
  }

  button.close {
    background: white;
    border-radius: 4px;
    padding: 0 8px;
    opacity: 0.65;
  }

  button.close:hover {
    opacity: 0.75;
    background: whitesmoke;
  }

  button.close span {
    font-size: 35px;
    line-height: 32px;
  }

  .info-container {
    display: flex;
    margin: 6px 0;
  }
}

// Info Box
.voz-modal .info-box {
  $border-color: rgba(27, 31, 35, 0.15);
  $border-radius: 6px;

  display: flex;

  border: 1px solid $border-color;
  border-radius: 6px;

  .info-label {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #212529;
    user-select: none;

    padding: 3px 12px;
    line-height: 18px;

    border-radius: 5px;
    border-radius: 5px;

    background-color: #f0f0f0;
  }

  .clickable-label {
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:hover,
    &:focus {
      background-color: #e2e6ea;
      transition: background-color 0.15s ease-in-out;
    }

    &:active {
      background-color: #dae0e5;
      transition: background-color 0.15s ease-in-out;
    }
  }

  .label-with-content {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid $border-color;
  }

  .info-content {
    display: flex;
    justify-content: center;
    padding: 1px 12px;
  }
}

.info-box + .info-box {
  margin-left: 10px;
}
