$voz-primary-1: #182142;
$voz-primary-2: #1f1fff;
$voz-primary-3: #323232;
$voz-primary-4: #8a8af9;
$voz-primary-5: #ffffff;

$voz-secondary-1: #f9d76c;
$voz-secondary-2: #00af3f;
$voz-secondary-3: #00c4bd;
$voz-secondary-4: #c9f3f1;
$voz-secondary-5: #005e62;
$voz-secondary-6: #c6e4d8;
$voz-secondary-7: #a1bd8b;
$voz-secondary-8: #264df2;
$voz-secondary-9: #575756;
$voz-secondary-10: #e2e2e2;
$voz-secondary-11: #eea661;
$voz-secondary-12: #ffe1c9;
$voz-secondary-13: #7264d8;
$voz-secondary-14: #c5c4f8;
$voz-secondary-15: #ded1b8;
$voz-secondary-16: #ff2d55;

$voz-white: #ffffff;
$voz-black: #212529;
$voz-text-error: #ff8d74;

$voz-gold: #eea661;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-to-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 991.98px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

@mixin animation-fade-in($duration: 0.1s) {
  animation-name: fade-in;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-duration: $duration;
  animation-iteration-count: 1;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
