@import "./variables";

// max-width: 767.98px - Small devices (landscape phones, less than 768px)
// max-width: 991.98px - Medium devices (tablets, less than 992px)

table.responsive-table {
  display: block;

  tr {
    padding: 4px 0px;
  }

  th,
  td {
    padding: 5px 0;
  }

  tr.desktop-table-headers {
    display: flex;
    color: #8e8e93;

    th {
      font-weight: 500;
    }

    @media (max-width: 991.98px) {
      display: none;
    }
  }

  tr:not(.desktop-table-headers) {
    border-top: 1px solid lightgray;
    &:hover {
      background-color: whitesmoke;
    }
  }

  .line-clamp {
    display: -webkit-box;
    width: 95%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }

  .responsive-table-row {
    display: flex;
    border-top: 1px solid lightgray;
    @media (max-width: 991.98px) {
      flex-direction: column;
    }
    &:hover {
      background-color: whitesmoke;
    }
  }

  .responsive-table-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .responsive-table-cell.action-cell {
    @media (min-width: 991.98px) {
      justify-content: center;
    }
  }

  .mobile-column-name {
    display: none;
    @media (max-width: 991.98px) {
      display: inline-block;
      min-width: 108px;
      font-weight: 600;
    }
  }

  .btn {
    padding: 4px 10px;
  }

  .btn-edit,
  .btn-qr,
  .btn-delete {
    @media (max-width: 991.98px) {
      min-width: 100px;
    }
  }

  .deleted-entity {
    color: #999999;
  }

  .action-cell .btn-edit {
    color: #64b7d9;
    &:active {
      color: #64b7d9;
    }
  }

  .action-cell .btn-qr {
    color: #111;
    &:active {
      color: #111;
    }
  }

  .action-cell .btn-delete {
    font-weight: 500;
    color: #ff3b30;
    &:active {
      color: #ff3b30;
    }
  }
}

/* used to specify % width on a table cell */
table.responsive-table .flex-basis {
  &-3 {
    flex-basis: 3%;
  }
  &-4 {
    flex-basis: 4%;
  }
  &-5 {
    flex-basis: 5%;
  }
  &-6 {
    flex-basis: 6%;
  }
  &-7 {
    flex-basis: 7%;
  }
  &-8 {
    flex-basis: 8%;
  }
  &-9 {
    flex-basis: 9%;
  }
  &-10 {
    flex-basis: 10%;
  }
  &-11 {
    flex-basis: 11%;
  }
  &-12 {
    flex-basis: 12%;
  }
  &-13 {
    flex-basis: 13%;
  }
  &-14 {
    flex-basis: 14%;
  }
  &-15 {
    flex-basis: 15%;
  }
  &-16 {
    flex-basis: 16%;
  }
  &-17 {
    flex-basis: 17%;
  }
  &-18 {
    flex-basis: 18%;
  }
  &-19 {
    flex-basis: 19%;
  }
  &-20 {
    flex-basis: 20%;
  }
  &-21 {
    flex-basis: 21%;
  }
  &-22 {
    flex-basis: 22%;
  }
  &-23 {
    flex-basis: 23%;
  }
  &-24 {
    flex-basis: 24%;
  }
  &-25 {
    flex-basis: 25%;
  }
  &-26 {
    flex-basis: 26%;
  }
  &-27 {
    flex-basis: 27%;
  }
  &-28 {
    flex-basis: 28%;
  }
  &-29 {
    flex-basis: 29%;
  }
  &-30 {
    flex-basis: 30%;
  }
  &-31 {
    flex-basis: 31%;
  }
  &-32 {
    flex-basis: 32%;
  }
  &-33 {
    flex-basis: 33%;
  }
  &-34 {
    flex-basis: 34%;
  }
  &-35 {
    flex-basis: 35%;
  }
  &-36 {
    flex-basis: 36%;
  }
  &-38 {
    flex-basis: 38%;
  }
  &-40 {
    flex-basis: 40%;
  }
  &-41 {
    flex-basis: 41%;
  }
  &-45 {
    flex-basis: 45%;
  }
  &-47 {
    flex-basis: 47%;
  }
  &-48 {
    flex-basis: 48%;
  }
  &-50 {
    flex-basis: 50%;
  }
  &-51 {
    flex-basis: 51%;
  }
  &-56 {
    flex-basis: 56%;
  }
  &-58 {
    flex-basis: 58%;
  }
  &-60 {
    flex-basis: 60%;
  }
  &-61 {
    flex-basis: 61%;
  }
  &-64 {
    flex-basis: 64%;
  }
  &-65 {
    flex-basis: 65%;
  }
  &-66 {
    flex-basis: 66%;
  }
  &-70 {
    flex-basis: 70%;
  }
  &-71 {
    flex-basis: 71%;
  }
  &-78 {
    flex-basis: 78%;
  }
  &-80 {
    flex-basis: 80%;
  }
  &-86 {
    flex-basis: 86%;
  }
  &-100 {
    flex-basis: 100%;
  }
}
