@import "./variables";

.voz-custom-panel {
  display: grid;
  grid-template-columns: 1.54fr 10fr;
  grid-template-rows: auto 1fr 1fr;
  grid-template-areas:
    "desktopNavArea headerArea"
    "desktopNavArea contentArea"
    "desktopNavArea contentArea";

  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "headerArea"
      "contentArea"
      "contentArea";
  }

  @media (min-width: 991.99px) and (max-width: 1024px) {
    grid-template-columns: 80px 10fr;
    grid-template-areas:
      "desktopNavArea headerArea"
      "desktopNavArea contentArea"
      "desktopNavArea contentArea";
  }

  max-width: 1540px;
  padding: 0 16px;
  margin: 30px auto;
}

// Desktop nav
.voz-custom-panel .desktop-nav-area {
  grid-area: desktopNavArea;
  @media (max-width: 991.98px) {
    display: none;
  }
}

// Header
.voz-custom-panel .header-area {
  grid-area: headerArea;
}

// Content
.voz-custom-panel .content-area {
  grid-area: contentArea;

  .white-wrapper,
  .table-container {
    margin-top: 16px;
    padding: 10px;
    border-radius: 16px;
    background-color: white;
    //box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.04);
  }
}

.content-area .table-container .table-description {
  @include flex-column-to-row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  .table-title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 991.98px) {
      margin: 15px 0;
    }
  }
}

.content-area .table-container .table-actions {
  @include flex-column-to-row;
  align-items: center;
  height: fit-content;
  flex-wrap: wrap;

  @media (max-width: 991.98px) {
    width: 100%;
    margin-bottom: 15px;
  }

  button {
    @media (max-width: 991.98px) {
      width: 100%;
    }
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 0.5px solid #ccc !important;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 200;
    letter-spacing: 0.5px;
  }

  .btn-edit {
    fa-icon {
      color: #64b7d9;
    }
    &:active {
      fa-icon {
        color: #64b7d9;
      }
    }
  }

  .btn-qr {
    fa-icon {
      color: #333;
    }
    &:active {
      fa-icon {
        color: #555;
      }
    }
  }

  .btn-delete {
    color: #600;
    background-color: rgba(102, 0, 0, 0.1) !important;
    font-size: 13px;
    font-weight: 200 !important;
    &:active {
      color: #ff3b30;
    }
  }

  button + button,
  form + div,
  button + form,
  div + button,
  form + button {
    @media (min-width: 991.98px) {
      margin-left: 16px;
    }
  }
}

.content-area .table-actions .search-wrapper {
  position: relative;
  height: 36px;
  // min-width: 300px;

  @media (max-width: 991.98px) {
    margin-bottom: 15px;
  }

  border-radius: 4px;

  .search-input {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    padding-left: 40px;
    padding-right: 4px;
    border-radius: 4px;

    background: #f6f6f6;
    border: 0.775804px solid #dddddd;
    box-sizing: border-box;
    box-shadow: inset 0px 0.775804px 3.87902px rgba(0, 0, 0, 0.1);
  }

  .icon {
    position: absolute;
    left: 13px;
    top: 10px;
    z-index: 0;
  }
}

.content-area .table-spinner-container {
  @include flex-center;
  margin-top: 50px;
  background: white;
  min-height: 1050px;
  width: 100%;

  .table-spinner {
    width: 3.5rem;
    height: 3.5rem;
    color: #e9ecef;
  }

  .table-spinner.spinner-border {
    width: 4rem;
    height: 4rem;
  }
}

.category-selector-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-left: 2px;
  margin-right: -6px;

  // Mobile-only styles
  @media (max-width: 991.98px) {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
    align-items: center;
  }
}

// .category-selector
.category-selector-wrapper .category-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: min-content;

  margin: 6px;

  // Mobile-only styles
  @media (max-width: 991.98px) {
    width: 100%;
  }

  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  color: #464646;

  transition: all 0.1s ease-in-out;

  .selector-text {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
  }

  .selector-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 58px;
    padding: 6px 12px;
    font-size: 30px;
    border-radius: 8px;
    background: #464646;
    color: #ffffff;
  }

  .selector-text p {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 4px;
    ng-icon {
      margin-right: 4px;
      margin-bottom: 4px;
      font-size: 16px;
    }
  }

  .selector-text h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }
}

.category-selector-wrapper .add-category-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding: 10px 12px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    margin: 0;
    font-weight: 400;
    height: 100%;
    width: 100%;
    border: 1px dashed lightgray;
    background-color: white;
  }

  p:hover {
    background-color: #f3f3f3;
  }
}

.category-selector-wrapper .category-selector.active {
  color: white;
  background-color: #464646;
  box-shadow: none;

  .selector-icon {
    color: #464646;
    background-color: #ffffff;
  }
}

.add-category-selector {
  display: flex;
  align-items: center;
  justify-content: center;

  height: min-content;
  margin: 6px;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0) !important;
  background: #ffffff;
  color: #464646;

  transition: all 0.1s ease-in-out;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0;
    height: 100%;
    width: 100%;
    border: 1px dashed lightgray;
    background-color: white;
  }

  p:hover {
    background-color: #f3f3f3;
  }
}
